import config from "../config.json";
import Features4 from "./composed/Features4";
import Features5 from "./composed/Features5";
import Features8 from "./composed/Features8";
import AboutWithGallery from "./composed/AboutWithGallery";
import Team1 from "./composed/Team1";
import Team4 from "./composed/Team4";
import Product1 from "./composed/Product1";
import Login1 from "./composed/Login1";
import { Separator1 } from "./composed/Separators";
import ProductPageHeader from "./uikit/Headers/ProductPageHeader";
import AboutUsHeader from "./uikit/Headers/AboutUsHeader";

import "./uikit/css/bootstrap.min.css";
import "./uikit/css/now-ui-kit.css";

import * as Backend from "@faintlines/backend-client";

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

Backend.configure({
    storyId: config.story.id,
    assetId: config.story.assetId,
});

const SECTION_COMPONENTS = {
    AboutUsHeader,
    ProductPageHeader,
    AboutWithGallery,
    Features4,
    Features5,
    Features8,
    Product1,
    Separator1,
    Team1,
    Team4,
    Login1,
};

export default function Page({ pageContext: { page } }) {
    const { title, loginRequired, loggedInSections } = page;
    const [sessionId, setSessionId] = useState(null);
    const isLoggedIn = sessionId !== null;

    const sections =
        loginRequired && isLoggedIn ? loggedInSections : page.sections;

    const handleLogin = (sid) => {
        setSessionId(sid);
        Backend.configure({ sessionId: sid });
    };

    const handleLogout = () => {
        setSessionId(null);
        Backend.configure({ sessionId: null });
    };

    return (
        <div>
            <Helmet>
                <title>{title}</title>
                {config.site.description ? (
                    <meta
                        name="description"
                        content={config.site.description}
                    />
                ) : null}
                {config.site.favicon ? (
                    <link rel="icon" href={config.site.favicon} />
                ) : null}
            </Helmet>
            <Sections
                sections={sections}
                onLogin={handleLogin}
                onLogout={handleLogout}
            />
        </div>
    );
}

function Sections({ sections, onLogin, onLogout }) {
    const isServerSections = typeof sections === "string";
    const [loading, setLoading] = useState(isServerSections);
    const [error, setError] = useState(false);
    const [serverSections, setServerSections] = useState(null);

    useEffect(() => {
        if (isServerSections && serverSections === null) {
            Backend.storyInteract({
                interactionType: "get_page",
                data: { pageId: sections },
            })
                .then(({ data }) => {
                    if (data.success) {
                        setServerSections(data.sections);
                    } else {
                        onLogout();
                        setError(true);
                    }
                })
                .catch(() => {
                    onLogout();
                    setError(true);
                })
                .finally(() => setLoading(false));
        }
    }, [sections]);

    if (loading) {
        // TODO
        return "Loading...";
    }

    if (error) {
        // TODO
        return "Error";
    }

    const actualSections = isServerSections ? serverSections : sections;
    return (
        <>
            {(actualSections || []).map((section, i) => (
                <Section
                    key={i}
                    section={section}
                    onLogin={onLogin}
                    onLogout={onLogout}
                />
            ))}
        </>
    );
}

function Section({ section, onLogin, onLogout }) {
    const { type, props } = section;
    const Comp = SECTION_COMPONENTS[type];
    return <Comp {...props} onLogin={onLogin} onLogout={onLogout} />;
}
